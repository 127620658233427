<template>
  <v-card
    style="min-height: 100vh"
    class="bg-white"
  >
    <v-app-bar app fixed outlined dense color="#ffffff" elevation="0.5">
      <v-icon @click="close()">mdi-close</v-icon>
      <v-toolbar-title class="text-caption font-weight-6 ml-3">
        <div class="font-weight-bold">
          Scan Dine-in <v-icon small>mdi-qrcode-scan</v-icon>
        </div>
        <small class="position-relative" style="top: -3px">{{
          profile.restaurant_name
        }}</small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-sheet class="qrcode-sheet p-3 pb-2 mb-3">
      <v-card 
        class="text-body-2 font-weight-5 mt-2" 
        elevation="0"
        v-if="error">
        <v-card-title class="text-subtitle-1 font-weight-6 blue--text">Enable Camera permission</v-card-title>
        <v-card-text>
          <ol>
            <li class="mt-1">Tap <v-icon>mdi-dots-vertical</v-icon> at your top / bottom right</li>
            <li class="mt-3">Go to <span class="mx-2"><v-icon class="mr-1" small>mdi-cog</v-icon>Settings</span> <v-icon color="black">mdi-arrow-right-thin</v-icon><span class="mx-2">Site settings</span></li>
            <li class="mt-2">Choose <span class="mx-2"><v-icon class="mr-1" small>mdi-video</v-icon>Camera</span></li>
            <li class="mt-3">Tap on <span class="font-weight-bold mx-2 primary--text">&lt;{{ currentUrl }}&gt;</span></li>
            <li class="mt-3">Select <span class="font-weight-bold mx-2 primary--text">&lt;Allow&gt;</span> to enable</li>
            <li class="mt-3"><span class="font-weight-bold mx-2 primary--text"><v-icon color="primary" class="mr-1" small>mdi-refresh</v-icon>Refresh</span> the page</li>
          </ol>
        </v-card-text>
      </v-card>
      <qrcode-stream :camera="camera" :torch="torchActive" @decode="onDecode" @init="onInit">
        <button class="btn-torch" @click="torchActive = !torchActive">
          <v-icon large color="primary" alt="toggle torch" v-if="!isLoading && torchSupported">
            {{ torchActive ? 'mdi-flashlight' : 'mdi-flashlight-off' }}
          </v-icon>
        </button>
      </qrcode-stream>
      <hr class="qrcode-scanning" v-if="!isLoading && !error" />
      <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-sheet>
  </v-card>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      version: null,
      isLoading: false,
      error: null,
      camera: 'auto',
      torchActive: false,
      torchSupported: false,
      title: 'EatMol',
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('merchant', {
      hasDinein: 'hasDinein',
    }),
    currentUrl() {
      return `https://${window.location.host}`;
    }
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('merchant', [
      'getMerchantByHostname',
    ]),
    ...mapMutations('cart', ['SET_SERVICE']),
    async onInit (promise) {
      this.isLoading = true;
      try {
        const { capabilities } = await promise;
        this.torchSupported = capabilities.torch;
      } catch (error) {
        this.error = error.name;
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      } finally {
        this.isLoading = false;
      }
    },
    onDecode(result) {
      this.camera = 'off';
      if (result.startsWith(`https://${window.location.host}/dinein/`)) {
        window.location.href = result;
      }
      setTimeout(() => this.camera = 'auto', 300);
    },
    close() {
      this.$router.push({ name: 'home' });
    },
  },
  async mounted() {
    this.$gtag.pageview(this.$route);

		// Get merchant profile
		await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);

    if (!this.hasDinein) {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>

<style scoped>
.v-toolbar {
  box-shadow: 0px 2px 4px -1px rgb(255 99 71 / 20%),
    0px 4px 5px 0px rgb(255 99 71 / 14%), 0px 1px 10px 0px rgb(255 99 71 / 12%) !important;
}
.qrcode-sheet {
	margin-top: 48px;
}
.qrcode-stream-wrapper {
  width: 100vw !important;
  height: calc(100vh - 48px) !important;
  position: absolute !important;
  top: 48px;
  left: 0;
}
.qrcode-stream-wrapper .btn-torch {
  position: absolute;
  bottom: 15vh;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
}
@-webkit-keyframes scanning {
  0%   { top: 20vh; opacity: 1 }
  100% { top: 70vh; opacity: 0.2 }
}

@keyframes scanning {
  0%   { top: 20vh; opacity: 1 }
  100% { top: 70vh; opacity: 0.2 }
}
.qrcode-scanning {
  height: 10px;
  background: linear-gradient(to top, rgba(255, 99, 71, 0.9), rgba(255, 99, 71, 0.5), rgba(255, 99, 71, 0.1)) ;
  position: absolute;
  z-index: 999;
  top: 10vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  animation-name: scanning;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.alert {
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}
@media screen and (min-width: 1024px) {
  .qrcode-stream-wrapper, .qrcode-scanning {
    width: 414px !important;
  }
  .alert {
    width: 374px;
  }
}
</style>
